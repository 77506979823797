import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { SearchEngineUtilsService } from '@commons/utils/search-utils.service';
import { environment } from '@environment';
import { SearchJobItem } from '@loyalty-v3/libs';
import { AlgoliaClientProvider } from '@wizbii-utils/angular/algolia';
import { Job } from '@wizbii/utils/models';
import { SearchParamsObject, SearchResponse } from 'algoliasearch';
import { Observable, from, map, switchMap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WizbiiJobsWebservice {
  readonly #algoliaClientProvider = inject(AlgoliaClientProvider);
  readonly #http = inject(HttpClient);
  readonly #searchEngineUtilsService = inject(SearchEngineUtilsService);

  #getClient() {
    return this.#algoliaClientProvider.getClient(environment.algolia.clientName)!;
  }

  searchJobs(
    query: string,
    contract?: string[] | string,
    domain?: string[] | string,
    searchParams?: Pick<
      SearchParamsObject,
      'page' | 'hitsPerPage' | 'facetFilters' | 'aroundLatLng' | 'aroundRadius' | 'filters'
    >
  ): Observable<SearchResponse<SearchJobItem>> {
    return this.#getClient().pipe(
      switchMap((client) =>
        from(
          client.searchForHits<SearchJobItem>({
            requests: [
              {
                indexName: environment.algolia.index.jobs,
                query,
                ...searchParams,
                ...(searchParams?.aroundLatLng ? { aroundLatLng: searchParams.aroundLatLng } : {}),
                ...(searchParams?.aroundRadius ? { aroundRadius: searchParams.aroundRadius } : {}),
                facetFilters: this.#searchEngineUtilsService.toFacetFilters({
                  'contract.id': contract,
                  'domain.id': domain,
                }),
              },
            ],
          })
        )
      ),
      map((response) => response.results[0])
    );
  }

  getJob(jobId: string): Observable<Job> {
    return this.#http.get<Job>(`${environment.api.job}/api/jobs/${jobId}?view=true`);
  }

  getJobSimilarJobs(jobId: string): Observable<Job[]> {
    return this.#http.get<Job[]>(`${environment.api.job}/api/jobs/${jobId}/similar-jobs`);
  }
}
